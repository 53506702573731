module.exports = function () {
	
	$('.filter__item').on('click', function(){
		var $filterItem = $(this),
			$filter = $filterItem.parents('[data-filter]'),
			items = $('#' + $filter.data('filter')).find('[data-filter-item]'),
			value = $filterItem.data('filter-for');

		if (!$filterItem.is('.filter__item_active')) {
			$filterItem.addClass('filter__item_active');
			$filterItem.siblings('.filter__item_active').removeClass('filter__item_active');

			if (value === 'all') {
				items.removeClass('f-hidden');
			} else {
				items.each(function(){
					var $item = $(this);

					if ($item.data('filter-item') === value) {
						$item.removeClass('f-hidden');
					} else {
						$item.addClass('f-hidden');
					}
				});
			}
		}

	});

};