require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.works').each(function(){
		var $slider = $(this),
			$sliderArrows = $('<div class="arrows"/>'),
			$sliderPrev = $('<div class="arrow arrow-prev" />'),
			$sliderNext = $('<div class="arrow arrow-next" />');

		$slider.append($sliderArrows);
		$sliderArrows.append($sliderPrev, $sliderNext);

		$slider.swiper({
			slidesPerView: 4,
			simulateTouch: false,
			prevButton: $sliderPrev,
			nextButton: $sliderNext,
			breakpoints: {
				479: {
					slidesPerView: 1
				},
				767: {
					slidesPerView: 2
				},
				1023: {
					slidesPerView: 3
				}
			}
		});
	});

}