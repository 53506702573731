require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.faq__slider')
		.after('<div class="faq__pagination" />')
		.swiper({
			speed: 500,
			pagination: '.faq__pagination',
			bulletClass: 'faq__bullet',
			bulletActiveClass: 'faq__bullet_active',
			paginationClickable: true,
			simulateTouch: false
		});

};