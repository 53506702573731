module.exports = function() {

	$('.show-more').each(function() {
		var $container = $(this);
		var $btnHolder = $container.find('.show-more__btn-holder');
		var $btn = $container.find('.show-more__btn');
		var $content = $container.find('.show-more__content');
		var id = '#' + $container.attr('id');

		$btn.on('click', function(event) {
			var url = $btn.attr('href');

			$.ajax({
				url: url,
				type: 'GET',
				beforeSend: function(data) {
					$btnHolder.addClass('loading');
				},
				success: function(data) {
					var $newContainer = $(data).find(id);
					var $newContent = $newContainer.find('.show-more__content');
					var $newBtn = $newContainer.find('.show-more__btn');

					$content.append($newContent.html());
					$btnHolder.removeClass('loading');

					if ($newBtn.length) {
						$btn.attr('href', $newBtn.attr('href'));
					} else {
						$btnHolder.remove();
					}
				}
			});

			event.preventDefault();
		});
	});

}