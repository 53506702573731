require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.class-reviews__slider').each(function(){
		var $slider = $(this),
			$sliderPagination = $('<div class="class-reviews__pagination"/>');

		$slider.after($sliderPagination);

		$slider.swiper({
			slidesPerView: 3,
			spaceBetween: 20,
			paginationClickable: true,
			pagination: $sliderPagination,
			bulletClass: 'class-reviews__bullet',
			bulletActiveClass: 'class-reviews__bullet_active',
			breakpoints: {
				767: {
					slidesPerView: 1
				},
				1023: {
					slidesPerView: 2
				}
			}
		});
	});

}