module.exports = function () {
	
	$('.stabs__tabLink').on('click', function(e){
		if (!$(this).parent('.stabs__tab').is('.stabs__tab_active')) {
			var $newTab = $(this).parent('.stabs__tab'),
				$newPanel = $($(this).attr('href')),
				$oldTab = $newTab.parents('.stabs').find('.stabs__tab_active'),
				$oldPanel = $($oldTab.children('.stabs__tabLink').attr('href'));

			$oldPanel.removeClass('stabs__panel_active');
			$oldTab.removeClass('stabs__tab_active');
			$newTab.addClass('stabs__tab_active');
			$newPanel.addClass('stabs__panel_active');
		}
		e.preventDefault();
	});

};