module.exports = function () {
	
	$('.tags__showall').on('click', function(){
		var $toggle = $(this),
			$hiddenTags = $toggle.parents('.tags').find('.tags__more');

		if ($toggle.is('.tags__showall_active')) {
			$toggle.text('Показать все');
			$hiddenTags.addClass('tags__more_hidden');
		} else {
			$toggle.text('Скрыть');
			$hiddenTags.removeClass('tags__more_hidden');
		}
		$toggle.toggleClass('tags__showall_active');
		return false;
	});

};