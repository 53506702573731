module.exports = function() {

	$('.questions').each(function() {
		var $holder = $(this);
		var $list = $holder.find('.questions__list');
		var $items = $holder.find('.questions__item');
		var $moreLinkHolder = $holder.find('.questions__more');
		var $moreLink = $moreLinkHolder.find('.questions__more-link');
		var quantityToShow = $moreLink.data('quantity');

		$items.each(function() {
			var $item = $(this);
			var $toggle = $item.find('.questions__caption');

			$toggle.on('click', function() {
				$item.toggleClass('questions__item_active');
			});
		});

		$moreLink.on('click', function() {
			var $hiddenItems = $list.find('.questions__item_hidden');

			if ($hiddenItems.length <= quantityToShow) {
				$hiddenItems.removeClass('questions__item_hidden');
				$moreLinkHolder.addClass('questions__more_hidden');
			} else {
				for (var i = 0; i < quantityToShow; i++) {
					$($hiddenItems[i]).removeClass('questions__item_hidden');
				}
			}

			return false;
		});
	});

}