require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.events').each(function(){
		var $slider = $(this),
			$sliderArrows = $('<div class="arrows"/>'),
			$sliderPrev = $('<div class="arrow arrow-prev" />'),
			$sliderNext = $('<div class="arrow arrow-next" />');

		$slider.append($sliderArrows);
		$sliderArrows.append($sliderPrev, $sliderNext);

		$slider.swiper({
			slidesPerView: 3,
			spaceBetween: 1,
			simulateTouch: false,
			prevButton: $sliderPrev,
			nextButton: $sliderNext,
			breakpoints: {
				480: {
					slidesPerView: 1
				},
				826: {
					slidesPerView: 2
				}
			}
		});
	});

};

