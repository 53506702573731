module.exports = function () {

	var eventTitle,
		eventTimer;

	$('body').on('mouseenter', '.eventdate-holder > span', function(){
		var $eventdate = $(this),
			$holder = $eventdate.parent('.eventdate-holder'),
			title = $holder.attr('title'),
			$eventtime = $eventdate.children('.eventtime');

		eventTitle = title;

		if ($eventtime.length) {
			clearTimeout(eventTimer);
			$eventtime.addClass('eventtime_visible');
		} else {
			$holder.removeAttr('title');
			$eventdate.append('<span class="eventtime">' + title + '</span>');
			setTimeout(function(){
				$eventdate.children('.eventtime').addClass('eventtime_visible');
			}, 1);
		}
	});

	$('body').on('mouseleave', '.eventdate-holder > span', function(){
		var $eventdate = $(this),
			$holder = $eventdate.parent('.eventdate-holder'),
			$eventtime = $eventdate.children('.eventtime');

		$eventtime.removeClass('eventtime_visible');
		$holder.attr('title', eventTitle);
		eventTimer = setTimeout(function(){
			$eventtime.remove();
		}, 200);
	});

	$('body').on('mouseenter', '.eventdate', function(){
		var $eventdate = $(this),
			title = $eventdate.attr('title'),
			$eventtime = $eventdate.children('.eventtime');

		eventTitle = title;

		if ($eventtime.length) {
			clearTimeout(eventTimer);
			$eventtime.addClass('eventtime_visible');
		} else {
			$eventdate.removeAttr('title');
			$eventdate.append('<span class="eventtime">' + title + '</span>');
			setTimeout(function(){
				$eventdate.children('.eventtime').addClass('eventtime_visible');
			}, 1);
		}
	});

	$('body').on('mouseleave', '.eventdate', function(){
		var $eventdate = $(this),
			$eventtime = $eventdate.children('.eventtime');

		$eventtime.removeClass('eventtime_visible');
		$eventdate.attr('title', eventTitle);
		eventTimer = setTimeout(function(){
			$eventtime.remove();
		}, 200);
	});

};