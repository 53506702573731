module.exports = function () {
	
	$('.nav__toggle').on('click', function(){
		$('.nav').toggleClass('nav_active');
	});

	$(document).on('click', function(e){
		if (!$(e.target).parents('.nav').length && !$(e.target).is('.nav')) {
			$('.nav_active').removeClass('nav_active');
		}
	});

};