module.exports = function () {
	
	$('.tabs__tab').on('click', function(e){
		if (!$(this).is('.tabs__tab_active')) {
			var $newTab = $(this),
				$newPanel = $($newTab.attr('href')),
				$oldTab = $newTab.parents('.tabs').find('.tabs__tab_active'),
				$oldPanel = $($oldTab.attr('href'));

			$oldPanel.removeClass('tabs__panel_active');
			$oldTab.removeClass('tabs__tab_active');
			$newTab.addClass('tabs__tab_active');
			$newPanel.addClass('tabs__panel_active');
		}
		e.preventDefault();
	});

};