require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.i-reviews__slider').each(function(){
		var $slider = $(this);
		var $sliderHolder = $slider.parent();
		var $sliderPagination = $('<div class="i-reviews__pagination"/>');
		var $sliderPrevBtn = $('<div class="i-reviews__arrow i-reviews__arrow_type_prev"/>');
		var $sliderNextBtn = $('<div class="i-reviews__arrow i-reviews__arrow_type_next"/>');

		$slider.after($sliderPrevBtn);
		$slider.after($sliderNextBtn);
		$sliderHolder.after($sliderPagination);

		$slider.swiper({
			slidesPerView: 'auto',
			paginationClickable: true,
			pagination: $sliderPagination,
			bulletClass: 'i-reviews__bullet',
			bulletActiveClass: 'i-reviews__bullet_active',
			prevButton: $sliderPrevBtn,
			nextButton: $sliderNextBtn
		});
	});

}