module.exports = function () {
	
  $('.gallery__link_video').on('click', function(event){
    var $link = $(this),
		videoId = $link.attr('href').replace('https://youtu.be/','');

	$link.after('<iframe src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&playsinline=1&showinfo=0&disablekb=1" frameborder="0" allowfullscreen class="gallery__video"/>');
	
	event.preventDefault();
  });

};
