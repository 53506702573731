module.exports = function() {

    if (!localStorage.getItem('cookie-alert')) {
        $.magnificPopup.open({
            items: {
                src: '<div class="popup">' +
                    '<p>Сайт может собирать метаданные пользователя (cookie, данные об IP-адресе и местоположении)» и если вы остаетесь на сайте, прочитав это сообщение, то это означает, что вы не против использования данных технологий</p>' +
                    '<p class="text-center" style="margin-bottom: 0;"><button class="btn btn-alt" type="button" onclick="localStorage.setItem(\'cookie-alert\', \'true\');$.magnificPopup.close();">Ок</button></p>' +
                    '</div>',
                type: 'inline'
            }
        });
    }

}