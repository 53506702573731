module.exports = function () {
	
	$('.slideto').on('click', function(e){
		var y = $($(this).attr('href')).offset().top;

		$('html,body').animate({
			scrollTop: y
		}, 300);

		e.preventDefault();
	});

};