require("./../../../bower_components/jquery-ui/ui/core");
require("./../../../bower_components/jquery-ui/ui/widget");
require("./../../../bower_components/jquery-ui/ui/datepicker");

module.exports = function () {

	$('.datepicker').each(function(){
		var $datepicker = $(this),
			dates = $datepicker.data('event-dates').split(','),
			eventDates =  {};

		for (var i = 0; i < dates.length; i++) {
			var eventDate = dates[i].split('T')[0],
				eventTime = dates[i].split('T')[1];

			eventDates[new Date(eventDate)] = eventTime;
		}

		$datepicker.datepicker({
			showOtherMonths: true,
			monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
			monthNamesShort: ['Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сен','Окт','Ноя','Дек'],
			firstDay: 1,
			dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
			dateFormat: 'dd.mm.yy',
			gotoCurrent: false,
			prevText: 'Раньше',
			nextText: 'Позже',
			beforeShowDay: function(date){
				var highlight = eventDates[date];

		        if (highlight) {
		             return [false, 'eventdate-holder', eventDates[date]];
		        } else {
		             return [false, '', ''];
		        }
			}
		});
	});

};