require("./../../../bower_components/magnific-popup/dist/jquery.magnific-popup.js");

var textbox = require('./textbox');

module.exports = function() {

	$.extend(true, $.magnificPopup.defaults, {
		tClose: 'Закрыть (Esc)',
		tLoading: 'Загрузка...',
		gallery: {
			tPrev: 'Назад (Стрелка влево)',
			tNext: 'Вперед (Стрелка вправо)',
			tCounter: '%curr% из %total%'
		},
		image: {
			tError: '<a href="%url%">Изображение</a> не может быть загружено.'
		},
		ajax: {
			tError: '<a href="%url%">Запрос</a> не может быть обработан.'
		},
		closeMarkup: '<button title="%title%" type="button" class="close close-popup"></button>',
		callbacks: {
			close: function() {
				if (this.currItem.src === '#subscribe-popup') {
					Cookies.set('doNotShowSubscribePopup', 'true', {
						expires: 14
					});
				}
			}
		}
	});

	$(document).on('click', '.close-popup', function(e) {
		$.magnificPopup.close();
		e.preventDefault();
	});



	// Galleries in popup
	$('.class-reviews').magnificPopup({
		delegate: '.class-reviews__link',
		type: 'image',
		gallery: {
			enabled: true
		}
	});

	$('.i-reviews').magnificPopup({
		delegate: '.i-reviews__link',
		type: 'image',
		gallery: {
			enabled: true
		}
	});

	$('.letter-reviews').magnificPopup({
		delegate: '.letter-reviews__link',
		type: 'image',
		gallery: {
			enabled: true
		}
	});

	$('.i-reviews-list').magnificPopup({
		delegate: '.i-reviews-list__link',
		type: 'image',
		gallery: {
			enabled: true
		}
	});



	// Image zoom popup
	$('.gallery__link:not(.gallery__link_video)').magnificPopup({
		type: 'image'
	});



	// Content popup
	$('.popup-init').magnificPopup({
		type: 'inline'
	});



	// Ajax content popup
	$('.ajax-popup-init').magnificPopup({
		type: 'ajax',
		callbacks: {
			ajaxContentAdded: textbox
		}
	});



	$(document).on('click', '.btn-slide-to', function(event) {
		event.preventDefault();

		var $target = $($(this).attr('href'));

		$.magnificPopup.close();
		$('html, body').animate({
			scrollTop: $target.offset().top
		}, 500);
	});



	// Subscribe popup
	if ($('#subscribe-popup').length && Cookies.get('doNotShowSubscribePopup') != 'true') {
		var duration = +$('#subscribe-popup').data('duration') * 1000;

		setTimeout(function() {
			$.magnificPopup.open({
				items: {
					src: '#subscribe-popup',
					type: 'inline'
				}
			});
		}, duration);
	}

};