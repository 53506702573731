require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.gallery__slider').each(function(){
		var $slider = $(this);
		var $sliderPagination = $('<div class="gallery__pagination"/>');
		var slidesQuantity = $slider.find('.swiper-slide').length;

		$slider.after($sliderPagination);

		$slider.swiper({
			slidesPerView: 'auto',
			paginationClickable: true,
			pagination: $sliderPagination,
			bulletClass: 'gallery__bullet',
			bulletActiveClass: 'gallery__bullet_active'
		});

		togglePaginationVisibility();
		$(window).on('resize', togglePaginationVisibility);

		function togglePaginationVisibility() {
			var windowWidth = $(window).width();

			if (windowWidth >= 768 && slidesQuantity > 3
				|| windowWidth >= 480 && windowWidth < 768 && slidesQuantity > 2
				|| windowWidth < 480 && slidesQuantity > 1) {

				$sliderPagination.removeClass('hidden');
			
			} else {

				$sliderPagination.addClass('hidden');

			}
		}
	});

}