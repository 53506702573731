module.exports = function () {
	
	$('[data-order]').on('click', function(e){
		var $order = $($(this).attr('href'));

		if ($order.is(':visible')) {
			$order.slideUp(300);
		} else {
			$('.order:visible').slideUp(300);
			$order.slideDown(300);
		}
		e.preventDefault();
	});

	$('[data-close-order]').on('click', function(e){
		var $order = $(this).parents('.order');

		$order.slideUp(300);
		e.preventDefault();
	});

};