module.exports = function () {
	
	$('.reference__image').on('click', function(e){
		var $tab = $(this).parent('.reference__tab'),
			$reference = $tab.parents('#reference'),
			image = '<img src="' + $(this).data('pic') + '" alt="" class="screenshot__image">',
			$screenshot = $reference.next('.screenshot');

		if (!$tab.is('.reference__tab_active')) {
			if ($screenshot.length) {
				$tab.siblings('.reference__tab_active').removeClass('reference__tab_active');
				$tab.addClass('reference__tab_active');
				$screenshot.find('.screenshot__image').remove();
				$screenshot.append(image);
			} else {
				$tab.addClass('reference__tab_active');
				$reference.after(
					'<div class="screenshot">' +
					'<button class="close close-screenshot" />' +
					image +
					'</div>');
				$screenshot = $reference.next('.screenshot');
			}
		}

		$(image).on('load', function(){
			$('html, body').animate({
				scrollTop: $screenshot.offset().top
			}, 300);
		});
	});

	$('body').on('click', '.close-screenshot', function(){
		var $screenshot = $(this).parents('.screenshot');

		$screenshot.slideUp(300, function(){
			$screenshot.remove();
			$('.reference__tab_active').removeClass('reference__tab_active');
		});
	});

};