module.exports = function () {

	$('.form__checkbox').on('change', function(){
		var $checkbox = $(this),
			$btn = $checkbox.parents('.form').find('.form__btn');

		if ($checkbox.prop('checked') === true) {
			$btn.prop('disabled', false);
		} else {
			$btn.prop('disabled', true);
		}
	});


	// Required checkboxes
	$('.required-checkbox').each(function(){
		var $checkbox = $(this);
		var $form = $checkbox.parents('form');

		checkForm($form);

		$checkbox.on('change', function(){
			checkForm($form);
		});
	});


	function checkForm($form) {
		var $checkboxes = $form.find('.required-checkbox');
		var $submitBtn = $form.find('[type=submit]');
		var isReadyToSubmit = true;

		$checkboxes.each(function(){
			var $checkbox = $(this);

			if ($checkbox.prop('checked') != true) {
				isReadyToSubmit = false;
			}
		});

		if (isReadyToSubmit) {
			$submitBtn.prop('disabled', false);
		} else {
			$submitBtn.prop('disabled', true);
		}
	}
};