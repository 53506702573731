module.exports = function() {

	$('.textbox').each(function() {
		var $holder = $(this);
		var $btn = $holder.find('.textbox__more-link');

		$btn.on('click', function() {
			$holder.toggleClass('textbox_open');
			return false;
		});
	});

};