"use strict";
require('browsernizr/test/touchevents');
require('browsernizr');
require("./../../bower_components/parsleyjs/dist/parsley.js");
require("./../../bower_components/parsleyjs/dist/i18n/ru.js");

var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");
var Cookies = window.Cookies = require("./../../bower_components/js-cookie/src/js.cookie");

var initCustomWidgets = function() {
    require('./modules/datepicker')();
    require('./modules/events')();
    require('./modules/eventtime')();
    require('./modules/faq')();
    require('./modules/filter')();
    // require('./modules/header')();
    require('./modules/input-mask')();
    require('./modules/nav')();
    require('./modules/order')();
    require('./modules/popup')();
    require('./modules/related')();
    require('./modules/reference')();
    require('./modules/reviews')();
    require('./modules/schedule')();
    require('./modules/search')();
    require('./modules/slideto')();
    require('./modules/special')();
    require('./modules/stabs')();
    require('./modules/tabs')();
    require('./modules/up')();
    require('./modules/works')();
    require('./modules/hide-arrows')();
    require('./modules/form-checkbox')();
    require('./modules/tags')();
    require('./modules/class-reviews')();
    require('./modules/textbox')();
    require('./modules/gallery')();
    require('./modules/questions')();
    require('./modules/playVideo')();
    require('./modules/iReviews')();
    require('./modules/clientReviews')();
    require('./modules/showMore')();
    require('./modules/sendForm')();
    require('./modules/playMovie')();
    require('./modules/cookieAlert')();
};

$(initCustomWidgets);