module.exports = function () {
	
	$('.search__toggle').on('click', function(){
		$('.search').toggleClass('search_active');
	});

	$(document).on('click', function(e){
		if (!$(e.target).parents('.search').length && !$(e.target).is('.search')) {
			$('.search_active').removeClass('search_active');
		}
	});

};