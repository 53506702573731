module.exports = function () {
	
	// Scroll to top
	$('.up').on('click', function(){
		$('html, body').animate({
			scrollTop: 0
		}, 500);
	});

	$(window).on('scroll', function(){
		if ($(document).scrollTop() > $(window).height()) {
			$('.up').addClass('up_visible');
		} else {
			$('.up').removeClass('up_visible');
		}
	}).scroll();

};