require("./../../../bower_components/magnific-popup/dist/jquery.magnific-popup.js");

module.exports = function () {

	$(document).on('click', '.movie__link', function(event) {
		var $link = $(this);
		var videoUrl = $link.attr('href');
		var videoId = videoUrl.replace('https://www.youtube.com/watch?v=','');

		if ($(window).width() > 1024) {
			$.magnificPopup.open({
				items: {
					src: videoUrl
				},
				type: 'iframe'
			});
		} else {
			$link.after('<iframe src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&playsinline=1&showinfo=0&disablekb=1" frameborder="0" allowfullscreen class="movie__iframe"/>');
		}

		event.preventDefault();
	});

};
