module.exports = function () {

	$(window).on('resize', function(){
		$('.arrows').each(function(){
			var $arrows = $(this);

			if ($arrows.find('.swiper-button-disabled').length >= 2) {
				$arrows.addClass('arrows_hidden');
			} else {
				$arrows.removeClass('arrows_hidden');
			}
		});
	}).resize();

}