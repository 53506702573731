require("./../../../bower_components/jquery-mask-plugin/dist/jquery.mask.js");

module.exports = function () {

	$('input[type="tel"]').mask('ZZZZZZZZZZZZZZZZZZZZ', {
	    translation: {
	      'Z': {
	        pattern: /[0-9+-/(/)\s]/, optional: true
	      }
	    }
	});

};