require("./../../../bower_components/magnific-popup/dist/jquery.magnific-popup.js");

module.exports = function() {
	$('.form-to-send').each(function() {
		var $form = $(this);
		var url = $form.attr('action') + '?isNaked=1';
		var successPopupId = $form.data('success-popup-id');

		$form.parsley();

		$form.on('submit', function(event) {
			if ($form.parsley().isValid()) {
				event.preventDefault();

				$form.addClass('loading');
				sendForm($form, url, successPopupId);
			}
		});
	});

	function sendForm($form, url, successPopupId) {
		var formData = $form.serialize();

		$.ajax({
			type: 'POST',
			url: url,
			data: formData,
			success: function(data) {
                $(successPopupId).html(data);
                $form.removeClass('loading');
                $form[0].reset();

                $.magnificPopup.open({
					items: {
						src: successPopupId,
						type: 'inline'
					}
				});
			},
			error: function() {
				if (!$(document).find('#error-popup').length) {
					$('body').append('<div class="mfp-hide popup popup_error" id="error-popup">' +
									 '<div class="error-caption">Возникла ошибка</div>' +
									 'Попробуйте отправить форму позже' +
									 '</div>');
				}

                $form.removeClass('loading');

                $.magnificPopup.open({
					items: {
						src: '#error-popup',
						type: 'inline'
					}
				});
			}
		});
	}
}