require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {

	$('.special__slider').each(function(){
		var $slider = $(this),
			$sliderArrows = $('<div class="arrows arrows-special"/>'),
			$sliderPrev = $('<div class="arrow arrow-prev" />'),
			$sliderNext = $('<div class="arrow arrow-next" />');

		$slider.after($sliderArrows);
		$sliderArrows.append($sliderPrev, $sliderNext);

		$slider.swiper({
			speed: 500,
			simulateTouch: false,
			prevButton: $sliderPrev,
			nextButton: $sliderNext
		});
	});

};